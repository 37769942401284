import { DATE_FORMATS, INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import PAYMENT_METHODS, { KEYS } from '$fbusiness/enums/options/paymentMethod';
import { intToArray } from '$gbusiness/helpers/util';
import { dateToString } from '$gbusiness/helpers/date';
import { addBusinessDays } from 'date-fns';

const generateDayOptions = (days) => {
  const thisDay = new Date();
  return intToArray(14).map((v, i) => {
    const deliveryDate = addBusinessDays(thisDay, days + i);
    return {
      value: dateToString(deliveryDate),
      labelText: dateToString(deliveryDate, DATE_FORMATS.DAY_WEEKDAY),
    };
  });
};

export const ADDRESS_FORM = (disabled) => [
  {
    items: [
      {
        ...INPUT_TYPES.GOOGLE_ADDRESS,
        label: 'INPUT.LABEL.SHIPPING_ADDRESS',
        placeholder: 'INPUT.PLACEHOLDER.ADDRESS',
        gridSize: 12,
        disabled,
        value: 'formatted',
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.LAST_NAME,
        value: 'name',
        label: 'INPUT.LABEL.NAME',
        disabled,
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.MOBILE,
        value: 'mobile',
        disabled,
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.EMAIL,
        gridSize: 12,
        disabled,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export const PAYMENT_FORM = (pickupOnly) => [
  {
    items: [
      {
        input: INPUT.RADIO,
        label: '',
        gridSize: 8,
        value: 'paymentMethod',
        options: PAYMENT_METHODS.filter((f) =>
          pickupOnly ? f.value === KEYS.PICKUP : f.value !== KEYS.PICKUP,
        ),
      },
    ],
  },
];

export const getShippingForm = ({ shippingOptions, onChange, shipping }) => [
  {
    items: [
      {
        input: INPUT.RADIO,
        label: '',
        gridSize: 10,
        isMultiline: true,
        value: 'shippingOption',
        className: 'shipping-option',
        options: shippingOptions,
        onChange,
      },
      {
        isHidden: () => true,
        input: INPUT.SELECT,
        label: 'INPUT.LABEL.DELIVERY_DATE',
        value: 'deliveryDate',
        options: generateDayOptions(shipping.days),
        validation: [input.requiredValidation],
      },
    ],
  },
];

export const ADDITIONAL_FORM = [
  {
    items: [
      {
        input: INPUT.TEXTAREA,
        gridSize: 12,
        label: 'INPUT.LABEL.ADDITIONAL_MESSAGE',
        value: 'additionalMessage',
        option: PAYMENT_METHODS,
      },
    ],
  },
];
