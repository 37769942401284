import React from 'react';
import { LoginScreen as GLoginScreen } from '$gbox/screens';
import { useDispatch } from 'react-redux';
import { initActions } from '$business/redux/init';

const LoginScreen: React.FC = () => {
  const dispatch = useDispatch();
  return <GLoginScreen onLogin={(values) => dispatch(initActions.login(values))} showLogo={false} />;
};

export default LoginScreen;
