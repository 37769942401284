import YES_NO_OPTIONS from '$gbusiness/enums/options/yestno';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import InputRowModel from '$gbusiness/models/inputRow';
import { dateToString } from '$gbusiness/helpers/date';

export const DAILY_REPORT_FORM = (regions, history): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.TEXT,
        label: 'SCREEN.DAILY_REPORT.COLS.AUTHOR',
        value: 'author',
        validation: [input.requiredValidation],
        gridSize: 4,
      },
      {
        input: INPUT.DATE,
        label: 'SCREEN.DAILY_REPORT.COLS.DATE',
        value: 'reportDate',
        onChange: ({ value }) => history.replace('/dailyreports/' + dateToString(value)),
        gridSize: 4,
      },
      {
        input: INPUT.SELECT,
        label: 'SCREEN.DAILY_REPORT.COLS.REGION',
        value: 'regionId',
        options: regions.map((r) => ({
          labelText: r.name,
          value: r.id,
        })),
        gridSize: 4,
      },
    ],
  },
];

export const DAILY_REPORT_ITEM_FORM = ({ regionOptions, storeOptions }): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.SELECT,
        label: 'SCREEN.DAILY_REPORT.ITEM.STORE',
        value: 'storeId',
        gridSize: 6,
        native: true,
        options: storeOptions,
        onChange: ({ setFieldValue, value }) => {
          const store = storeOptions.find((s) => s.value === Number(value));
          if (store?.count) setFieldValue('regionId', store.count);
        },
        validation: [input.requiredValidation],
      },
      {
        input: INPUT.SELECT,
        disableFastField: true,
        label: 'SCREEN.DAILY_REPORT.ITEM.REGION',
        value: 'regionId',
        native: true,
        gridSize: 6,
        options: regionOptions,
        validation: [input.requiredValidation],
      },
      {
        input: INPUT.SELECT,
        label: 'SCREEN.DAILY_REPORT.ITEM.YESNO',
        value: 'yes',
        options: YES_NO_OPTIONS,
        native: true,
        gridSize: 4,
      },
      {
        input: INPUT.TEXT,
        label: 'SCREEN.DAILY_REPORT.ITEM.INVOICENO',
        value: 'invoiceNo',
        gridSize: 3,
      },
      {
        input: INPUT.TEXT,
        label: 'SCREEN.DAILY_REPORT.ITEM.CHECKNO',
        value: 'checkNo',
        gridSize: 4,
      },
      {
        ...INPUT_TYPES.AMOUNT,
        postfix: '',
        label: 'SCREEN.DAILY_REPORT.ITEM.CHECK_AMOUNT',
        value: 'checkAmount',
        gridSize: 4,
      },
      {
        ...INPUT_TYPES.AMOUNT,
        postfix: '',
        label: 'SCREEN.DAILY_REPORT.ITEM.CASH_AMOUNT',
        value: 'cashAmount',
        gridSize: 4,
      },
      {
        input: INPUT.TEXTAREA,
        label: 'SCREEN.DAILY_REPORT.ITEM.MEMO',
        value: 'note',
        gridSize: 8,
      },
    ],
  },
];
