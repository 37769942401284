import { FONT, SPACE } from '$gstyles';
import { mobileStyle, splitOverStyle } from '$gstyles/utils';
import { IonHeader, IonTitle } from '@ionic/react';
import styled from 'styled-components';

const HEADER_HEIGHT = '56px';

export const IonHeaderWrapper = styled(IonHeader)`
  ion-buttons > * {
    display: flex;
    align-items: center;

    .label {
      font-size: ${FONT.SMALL};
      color: var(--ion-color);
      margin-left: ${SPACE.XSMALL};
      text-transform: uppercase;
    }
  }

  svg.MuiSvgIcon-root {
    font-size: 32px;
  }
  ion-button.receipt {
    min-width: 44px;
    height: 48px;
  }
  .MuiBadge-anchorOriginTopRightRectangle {
    top: 2px;
    right: 2px;
  }
  .login.link {
    margin-right: ${SPACE.LARGE};
  }
  .aboutus.link {
    margin-right: ${SPACE.MEDIUM};
  }

  ion-toolbar,
  .MuiSvgIcon-root {
    --ion-color-base: #fff !important;
    color: var(--ion-color-primary);
  }

  ion-toolbar {
    /* --min-height: 48px; */
  }

  .icon-button {
    margin-right: ${SPACE.SMALL};
    margin-left: ${SPACE.SMALL};
  }

  .title-section {
    flex-direction: column;
    align-items: start;
    .logo-section {
      width: 100%;
    }
    .brand-wrapper {
      height: 36px;
    }
  }

  ${splitOverStyle(`
    ion-toolbar {
      --min-height: 80px;
    }
    .title-section {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .logo-section {
        max-width: 260px;
        flex: 1;
      }
      .img-container {
        height: 40px !important;
        width: 400px !important;
      }
      .call-image {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
  `)}
`;

export const SearchBarWrapper = styled(IonHeader)`
  background-color: var(--ion-color-tertiary);
  .ac-wrapper {
    margin: 4px;
  }
  .search-wrapper {
    justify-content: flex-end;
  }
  .news-flash {
    align-items: center;
    padding: 10px;
    .annoucement {
      padding-left: 10px;
    }
    .BaseBadge-badge {
      top: 2px;
    }
  }
  ${mobileStyle(`
    .search-wrapper {
      justify-content: center;
    }
  `)}
`;

export const BrandWrapper = styled(IonTitle)`
  text-align: center;
  height: 48px;
  width: 100%;
  padding: 0;

  &.has-caption {
  }
  .logo {
    width: 100%;
    height: calc(${HEADER_HEIGHT} - 8px) !important;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  &.has-logo.has-caption .logo {
    height: calc(${HEADER_HEIGHT} - 24px);
  }
  &:not(.has-caption):not(.has-logo) {
    font-size: ${FONT.LARGE};
  }
  &.has-caption .caption {
    font-size: ${FONT.LARGE};
  }
  &.has-logo.has-caption .caption {
    font-size: ${FONT.SMALL};
    opacity: 0.8;
    margin: 0;
    padding: 0;
    line-height: 12px;
  }
  ion-button.receipt {
    min-width: 44px;
    height: 48px;
  }
  ${splitOverStyle(`
  margin-left: ${SPACE.LARGE};
    .logo {
      height: calc(${HEADER_HEIGHT} + 10px) !important;
    }
  `)}
`;

export const Logo = styled.div`
  ${(p) => p.url && 'background-image: url(' + p.url + ');'}
`;
