export const PATH = {
  LOGIN: '/login',
  HOME: '/home',
  MENU: '/category',
  ITEM: '/product',
  INVENTORY: '/inventory',
  FACTORIES: '/factories',
  DEPARTMENTS: '/inventory/1',
  CATEGORIES: '/inventory/2',
  ITEMS: '/inventory/3',
  CHECKOUT: '/menucheckout',
  THANKYOU: '/thankyou',
  ABOUT_US: '/aboutus',
  NOTIFICATION: '/notification',
};

export default PATH;
