import { IonToolbar, IonButtons, IonMenuToggle } from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, AccountCircle, ShoppingCart, Folder, LockOpen, Article } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import { IonHeaderWrapper, SearchBarWrapper } from './styles';
import Brand from './brand';
import { Autocomplete } from '$gcomponents/reusables';
import { configs } from '$configs';
import { MobileOver, Split } from '$gstyles/wrapper';
import { Flex } from '$gstyles/wrapper';
import { Link } from '$gcomponents/primitives';
import PopOver from '$gcomponents/reusables/popOver';
import AccountMenu from './accountMenu';
import intl from '$gintl';
import LoginModal from '../loginModal';
import { loadingActions } from '$gbusiness/redux/loading';
import CurrentStateModel from '$fbusiness/models/currentState';
import { getPublicFactoryId } from '$gbusiness/helpers/util';
import ItemListContainer from '../itemListContainer';
import { useHistory } from 'react-router';
import { ColAuto, Div, Row, SPACE } from '$gstyles';
import FPATH from '$fbusiness/enums/paths';
import PATH from '$business/enums/paths';
import BroadcastModel from '$fbusiness/models/broadcast';

interface MainHeaderProps {
  logo?: string;
  currentState: CurrentStateModel;
  broadcasts: Array<BroadcastModel>;
}

export const AUTO_COMPLETE = {
  method: 'POST',
  minChar: 2,
  renderSuggestion: (e) => (
    <Flex>
      {e.sku == null && e.quantity === undefined ? (
        <>
          <Folder style={{ color: '#F7D367' }} />
          <Div marginLeft={SPACE.SMALL}>{e.name}</Div>
        </>
      ) : (
        <div>{`${e.name} (${e.id})`}</div>
      )}
    </Flex>
  ),
  getLabel: (obj) => obj.name,
  onEnter: () => {
    console.log('ENETER');
  },
  deriveRaw: (response) => {
    return [...response.categories, ...response.items];
  },
};

const MainHeader: React.FC<MainHeaderProps> = ({ currentState, logo, broadcasts }) => {
  const [showLogin, setShowLogin] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const cartCount = useSelector(
    (state: any) => state.localStorage.cart?.products.filter((p) => p.isAdded).length,
  );
  const { isLoggedIn } = currentState;
  const onClickLogin = () => {
    dispatch(loadingActions.showLoginModal());
  };

  const onClickBroadcast = () => {
    console.log('ONCLICK BROADCAST');
    history.push(FPATH.BROADCASTS);
  };

  const SideMenuToggleIcon = (
    <Split>
      <IonMenuToggle menu="start">
        <IconButton className="link">
          <Menu />
        </IconButton>
      </IonMenuToggle>
    </Split>
  );
  const AccountMenuToggleIcon = isLoggedIn ? (
    <PopOver
      className="icon-button link"
      component={
        <Flex>
          <AccountCircle fontSize="large" />
          <MobileOver className="label">{intl('MENU.MY_ACCOUNT')}</MobileOver>
        </Flex>
      }>
      <AccountMenu isLoggedIn={isLoggedIn} />
    </PopOver>
  ) : (
    <div className="link uppercase login" onClick={onClickLogin}>
      <LockOpen fontSize="large" />
      <div className="label">{intl('BUTTON.LOGIN')}</div>
    </div>
  );
  const CartMenuToggleIcon = (
    <IonMenuToggle className="icon-button link" autoHide={false} menu="end">
      <Badge badgeContent={cartCount} color="error">
        <ShoppingCart fontSize="large" />
      </Badge>
      <MobileOver className="label">{intl('COMMON.CART')}</MobileOver>
    </IonMenuToggle>
  );

  return (
    <>
      <IonHeaderWrapper id="sidemenu">
        <IonToolbar color="light">
          <IonButtons slot="start">{SideMenuToggleIcon}</IonButtons>
          <Flex className="title-section">
            <div className="logo-section">
              <Link route="/">
                <Brand logo={logo} />
              </Link>
            </div>
            {/* <div className="call-image">
              <Img
                src={configs.display.imagePath + 'call.png' || ''}
                className="img-container"
                width="180px"
                size="contain"
                height="22px"
              />
            </div> */}
          </Flex>
          <IonButtons slot="end">
            {AccountMenuToggleIcon}
            {isLoggedIn && CartMenuToggleIcon}
          </IonButtons>
          <LoginModal disableRegister={true} show={showLogin} onClose={() => setShowLogin(false)} />
        </IonToolbar>
      </IonHeaderWrapper>
      <SearchBarWrapper>
        <Row>
          {broadcasts.length > 0 && !currentState.isGuest && (
            <ColAuto justifyContent="center">
              <div className="news-flash flex pointer" onClick={onClickBroadcast}>
                <Badge color="error" badgeContent={broadcasts.length}>
                  <Article />
                </Badge>
                <div className="annoucement">{`Recent Announcement${broadcasts.length > 1 ? 's' : ''}`}</div>
              </div>
            </ColAuto>
          )}
          <ColAuto>
            <ItemListContainer currentState={currentState}>
              {(onAddCart, onViewItem) => (
                <Flex className="search-wrapper">
                  <Autocomplete
                    endpoint={configs.api.item.search}
                    AC={{
                      ...AUTO_COMPLETE,
                      extraParam: { factoryId: getPublicFactoryId() },
                      onSelect: (value) => {
                        if (value.sku == null && value.quantity === undefined) {
                          history.push(PATH.MENU + '/' + value.id);
                        } else {
                          onViewItem(value.id);
                        }
                      },
                    }}
                    width="300px"
                    height="32px"
                  />
                </Flex>
              )}
            </ItemListContainer>
          </ColAuto>
        </Row>
      </SearchBarWrapper>
    </>
  );
};

export default MainHeader;
