import { INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { requiredValidation, validateEmail, validatePassword } from '$gbusiness/helpers/input';

export const USER_FORM = (isMe): Array<InputRowModel> => [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        validation: [requiredValidation],
      },
      {
        ...INPUT_TYPES.LAST_NAME,
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.EMAIL,
        gridSize: 6,
        disabled: isMe,
        validation: [
          requiredValidation,
          {
            condition: (v) => !validateEmail(v),
            message: 'INVALID_EMAIL',
          },
        ],
      },
      {
        ...INPUT_TYPES.PASSWORD,
        gridSize: 6,
        validation: [
          {
            condition: (v) => !validatePassword(v),
            message: 'INVALID_PASSWORD',
          },
        ],
      },
    ],
  },
];

export default USER_FORM;
